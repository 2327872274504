<template>
  <div class="flex items-center justify-center w-full px-16 sm:px-24">
    <div class="flex flex-col max-w-md w-full text-center">
      <img :src="logoImage" alt="avisfordele" class="w-230 mx-auto">
      <h2 class="mt-24 mb-8 font-extrabold text-2xl leading-bigger">Sign in to your account</h2>
      <p class="mb-32 text-grey-400">Contact us at <el-button tag="a" href="mailto:mail@avisfordele.dk" type="link" class="inline-flex">mail@avisfordele.dk</el-button> or contact your consultant</p>

      <ValidationObserver ref="loginForm">
        <form class="w-full" @submit.prevent="onResetPassword">
          <div class="shadow-sm">
            <el-input
              v-if="!isResetPasswordStep"
              v-model="email"
              placeholder="Email address"
              input-class="w-full rounded-t rounded-b focus:z-10 relative"
              input-name="email"
              :rules="{ required: true, email: true }"
              :disabled="isLoading"
            />

            <template v-else>
              <el-input
                v-model="password"
                type="password"
                placeholder="Password"
                input-class="w-full rounded-t focus:z-10 relative"
                input-name="password"
                rules="required|validate_pass|password:@confirm"
                :disabled="isLoading"
                class="-mt-1"
              />
              <el-input
                v-model="passwordConfirm"
                type="password"
                placeholder="Password Confirm"
                input-class="w-full rounded-b focus:z-10 relative"
                input-name="confirm"
                :rules="{ required: true, validate_pass: true }"
                :disabled="isLoading"
                class="-mt-1"
              />
            </template>
          </div>

          <div class="flex justify-end items-center my-24">
            <el-button tag="a" type="link" @click="$router.push({ name: 'Login' })">Already have an account? Login!</el-button>
          </div>

          <el-button
            tag="button"
            type="primary-dark"
            :loading="isLoading"
            class="w-full group mt-24"
          >
            <template #icon>
              <span class="icon-lock text-green text-2lg block group-hover:text-green-300" />
            </template>
            {{ isResetPasswordStep ? 'Update password': 'Reset password' }}
          </el-button>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

const ElButton = () => import('@/components/shared/ELButton')
const ElInput = () => import('@/components/shared/ELInput')

export default {
  name: 'ResetPasswordPage',

  components: { ElInput, ElButton },

  data () {
    return {
      email: '',
      password: '',
      passwordConfirm: '',
      resetToken: null,
      resetEmail: null,
      isLoading: false
    }
  },

  computed: {
    logoImage () {
      return require('@/assets/images/paperboy-frontpage.png')
    },

    isResetPasswordStep () {
      return this.resetToken && this.resetEmail
    }
  },

  created () {
    const { token, email } = this.$route.query
    this.resetToken = token
    this.resetEmail = email
  },

  methods: {
    ...mapActions(['showNotification', 'requestResetPassword', 'resetPassword']),

    ...mapMutations(['loading']),

    onResetPassword () {
      this.$refs.loginForm.validate().then(isValid => {
        if (!isValid) {
          const errors = []

          // generate array of arrows
          Object.keys(this.$refs.loginForm.errors).map(error => {
            errors.push(...this.$refs.loginForm.errors[error])
          })

          this.showNotification({ type: 'error', message: errors })
          return false
        } else {
          this.isLoading = true

          // reset password
          if (this.isResetPasswordStep) {
            this.resetPassword({ email: this.resetEmail, token: this.resetToken, password: this.password })
              .then(res => {
                this.isLoading = false
                this.showNotification({ type: 'success', message: 'Your password has been reset, please login using the new password' })
                this.$router.push({ name: 'Login' })
              })
              .catch(() => {
                this.isLoading = false
                this.showNotification({ type: 'error', message: 'Something went wrong. Please, try again later.' })
              })
          } else {
            // request to reset password
            this.requestResetPassword({ email: this.email, url: window.location.href })
              .then(() => {
                this.isLoading = false
                this.showNotification({ type: 'success', message: 'If the entered email matches an account, we send you an email with a reset password link' })
                this.$router.push({ name: 'Login' })
              })
              .catch(() => {
                this.isLoading = false
                this.showNotification({ type: 'error', message: 'Something went wrong. Please, try again later.' })
              })
          }
        }
      })
    }
  }
}
</script>
